import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { CompanyProfileQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import company_overview_image from "../../images/siteImages/companyProfile/WHO_WE_ARE.png";
import quick_account_opening from "../../images/siteImages/companyProfile/QUICK_ACCOUNT_OPENING.png";
import trading_experiance from "../../images/siteImages/companyProfile/TRADING_EXPERIANCE.png";
import easy_to_use from "../../images/siteImages/companyProfile/EASY_TO_USE.png";
import mt4_platform from "../../images/siteImages/companyProfile/MT4_PLATFORM.png";
import deposits from "../../images/siteImages/companyProfile/DEPOSITS.png";
import wire_transfer from "../../images/siteImages/companyProfile/WIRE_TRANSFER.png";
import visa from "../../images/siteImages/companyProfile/VISA.png";
import netler from "../../images/siteImages/companyProfile/NETLER.png";
import skrill from "../../images/siteImages/companyProfile/SKRILL.png";
import PaymentSlider from "./PaymentSlider";

const why_image_icon = [
  quick_account_opening,
  trading_experiance,
  easy_to_use,
  mt4_platform,
  deposits,
  trading_experiance,
];

const payment_method_box_icon = [wire_transfer, visa, netler, skrill];

const CompanyProfile = () => {
  const language = useSelector((state) => state.language);
  const companyProfileData = CompanyProfileQuery(language);
  console.log("aaaaa", companyProfileData);
  const { seo_page_title, seo_page_description, seo_page_keywords } =
    companyProfileData;
  const {
    company_overview,
    company_overview_body,
    // company_overview_image,
    key_figures_body,
    key_figures_boxes,
    key_figures_heading,
    why_heading,
    why_reasons_box,
    payment_methods_heading,
    payment_methods_description,
    payment_methods_box,
    professional_skills_heading,
    professional_skills_description,
    professional_skills_boxes,
  } = companyProfileData;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Layout {...{ seo_page_title, seo_page_description, seo_page_keywords }}>
      <section className="company-profile line-height-lg">
        <Container className="">
          <Row>
            <Col lg={5}>
              <h2 className="pt-5 mt-5 text-bold-md text-black">
                {company_overview.text}
              </h2>
              <p className="text-gray mt-5">{company_overview_body.text}</p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={6}>
              <figure>
                <div>
                  <img
                    alt="ddd"
                    src={company_overview_image}
                    className="img img-w-100"
                  />
                </div>
              </figure>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-blue text-light">
        <Container>
          <Row>
            <Col lg={12}>
              <h4 className="text-lg text-bold-md pb-3 text-light">
                {key_figures_heading.text}
              </h4>
              <PrismicRichText field={key_figures_body.richText} />
            </Col>
          </Row>

          <Row className="mt-5">
            {key_figures_boxes.map((item, boxInd) => (
              <Col md={3} sm={12} key={"sdf" + boxInd}>
                <div className="items-counter text-center">
                  <p className="value text-blue">
                    <span className="count_up">
                      <CountUp
                        delay={1}
                        end={parseInt(item.number_only.text)}
                        prefix={item.number_prefix.text}
                        suffix={item.number_sufix ? item.number_sufix.text : ""}
                      />
                      {/* {item.key_feature_box_title.text} */}
                    </span>
                  </p>
                  <p className="title text-dark">
                    {item.key_feature_box_description.text}{" "}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="pt-5 my-5">
        <Container>
          <h2 className="text-center text-md text-bold-md text-dark mb-5 ">
            {why_heading.text}
          </h2>
          <Row>
            {why_reasons_box.map((reason, reasonInd) => (
              <Col
                lg={4}
                md={6}
                className="icon-square-container mb-4"
                key={"reasonInd" + reasonInd}
              >
                <div className="d-flex">
                  <div className="box-icon mx-4">
                    <div className="icon-square">
                      <img
                        alt="ddd"
                        src={why_image_icon[reasonInd]}
                        className="icon-width"
                        style={{ width: "45px" }}
                      />
                    </div>
                  </div>
                  <div className="box-content">
                    <h6 className="text-bold-md mb-3">
                      {reason.why_title.text}
                    </h6>
                    <p className="text-gray line-height-lg">
                      {reason.why_description.text}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="bg-gray py-5 section-split">
        <Container className="my-5">
          <Row>
            <Col lg={5}>
              <h2 className="text-lg text-bold-md mb-3">
                {payment_methods_heading.text}
              </h2>
              <p className="mb-4 text-gray text-md line-height-lg">
                {payment_methods_description.text}
              </p>

              <div
                className="logo-carousel-two owl-carousel owl-loaded owl-drag"
                style={{ direction: "ltr" }}
              >
                <Carousel
                  removeArrowOnDeviceType={["mobile", "tablet", "desktop"]}
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  infinite={true}
                >
                  <div className="col-sm-12" style={{ marginBottom: "2rem" }}>
                    <div className="carousel-single">
                      {payment_methods_box.map(
                        (item, index) =>
                          index % 2 === 0 && (
                            <div className="logo-box" key={"reasonInd" + index}>
                              <a
                                href={item.payment_method_box_link.text}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  textAlign: "center",
                                  height: "inherit",
                                  width: "100%",
                                }}
                              >
                                <img
                                  alt="ddd"
                                  src={payment_method_box_icon[index]}
                                  style={{ margin: "28px auto !important" }}
                                />
                              </a>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12" style={{ marginBottom: "2rem" }}>
                    <div className="carousel-single">
                      {payment_methods_box.map(
                        (item, index2) =>
                          index2 % 2 !== 0 && (
                            <div
                              className="logo-box"
                              key={"reasonInd" + index2}
                            >
                              <a
                                href={item.payment_method_box_link.text}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  textAlign: "center",
                                  height: "inherit",
                                  width: "100%",
                                }}
                              >
                                <img
                                  alt="ddd"
                                  src={payment_method_box_icon[index2]}
                                  style={{ margin: "28px auto !important" }}
                                />
                              </a>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </Carousel>
              </div>
            </Col>
            <Col lg={2}></Col>
            <Col lg={5}>
              <h2 className="text-lg text-bold-md mb-3">
                {professional_skills_heading.text}
              </h2>
              <p className="mb-4 text-gray text-md line-height-lg">
                {professional_skills_description.text}
              </p>

              {professional_skills_boxes.map((item, skillInd) => (
                <div className="progress-wrapper" key={"reasonInd" + skillInd}>
                  <div className="labels d-flex justify-content-between mb-2">
                    <span>{item.professional_skills_box_title.text}</span>
                    <span className="text-bold-md">
                      {item.professional_skills_box_level.text}%
                    </span>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-secondary"
                      role="progressbar"
                      style={{
                        width: item.professional_skills_box_level.text + "%",
                      }}
                      aria-valuenow="99"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {" "}
                    </div>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
      
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(CompanyProfile);
